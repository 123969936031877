<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item>
						<el-select v-model="screenForm.key_world_type" class="w100">
							<el-option v-for="(item,index) in keywordOption" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.key_world" clearable :placeholder="$t('teacher.placeholder.keyword')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model="screenForm.sort" class="w100" clearable :placeholder="$t('teacher.placeholder.sort')">
							<el-option v-for="(item,index) in sortOption" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongClass')">
						<el-select v-model="screenForm.class_id" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList" size="small">
					<el-table-column :label="$t('teacher.tableHead.member')">
						<template slot-scope="scope">
							<div class="user-info">
								<img class="pic" :src="scope.row.portrait" @error="avatarLoadError" alt="">
								<div class="info-main">
									<div class="name">
										{{scope.row.real_name?scope.row.real_name:scope.row.user_name}}<span>({{$t('teacher.member.sno')+":"}}{{scope.row.uuy_student_code}})</span>
									</div>
									<div class="time">{{$t('teacher.member.regtime')+":"}}{{scope.row.create_time | parseTime()}}</div>
									<div class="email" v-if="scope.row.email"><i
											class="el-icon-message"></i><span>{{scope.row.email}}</span></div>
									<div class="email" v-else><i
											class="el-icon-phone-outline"></i><span>{{scope.row.phone_mob}}</span></div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.logins')" prop="logins" align="center"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.lastLogin')" prop="last_login" align="center">
						<template slot-scope="scope">{{scope.row.last_login | parseTime()}}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.belongClass')" prop="class_name"></el-table-column>
				</el-table>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchClass, getMemberList } from '@/api/teacher';
	const defaultForm = {
		key_world: '',
		key_world_type: '4',
		sort: '',
		class_id: '',
	}
	export default {
		data() {
			return {
				screenForm: Object.assign({}, defaultForm),
				keywordOption: Object.assign([], this.$t('teacher.option.memberKeyword')),
				sortOption: Object.assign([], this.$t('teacher.option.memberSort')),
				classOption: [],
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
			}
		},
		mounted() {
			this.getClassOption();
			this.getPageList();
		},
		methods: {
			avatarLoadError(e) {
				e.target.src = this.$imageErrorUser;
				e.target.style.objectFit = 'fill';
			},
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPageList() {
				let params = {
					...this.screenForm,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				getMemberList(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {
			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;

			.w100 {
				width: 100px;
			}
		}

		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
			}

			.user-info {
				display: flex;
				align-items: center;

				.pic {
					width: 46px;
					height: 46px;
					border-radius: 50%;
				}

				.el-avatar {
					font-size: 26px;
				}

				.info-main {
					margin-left: 12px;

					div {
						line-height: 20px;
					}

					.name {
						font-weight: bold;

						span {
							font-weight: normal;
							color: $--seller-primary;
							margin-left: 10px;
						}
					}

					.time {
						color: #999999;
					}

					.email {
						color: #333333;
						line-height: 20px;

						i {
							color: $--seller-primary;
							font-size: 14px;
							margin-right: 6px;
							line-height: 20px;
						}
					}
				}
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
</style>
